const permissions = {
	loans: {
		permission: ['VIEW_LOANS_PAISA'],
		export: {
			permission: ['VIEW_LOANS_PAISA'],
		},
		table_row: {
			permission: ['VIEW_LOANS_PAISA'],
			export_loan: {
				permission: ['VIEW_LOANS_PAISA'],
			},
			pledge_receipt: {
				permission: ['VIEW_PLEDGE_CARD_PAISA'],
			},
			record_manual_payment: {
				permission: ['CREATE_PAYMENT'],
			},
		},
	},
	payments: {
		permission: ['VIEW_PAYMENT'],
	},
	refunds: {
		permission: ['VIEW_PAYMENT'],
	},
	fund_transfer: {
		permission: ['VIEW_PAYMENT'],
		export: {
			permission: ['VIEW_PAYMENT'],
		},
	},
	payout: {
		permission: ['VIEW_PAYMENT'],
		table_row: {
			reinitiate: { permission: ['CREATE_PAYMENT'] },
			refresh: { permission: ['VIEW_PAYMENT'] },
			cancel: { permission: ['DELETE_PAYMENT'] },
		},
		activate_payout: {
			permission: ['EDIT_PAYMENT'],
		},
	},
	config: {
		reports: {
			permission: ['VIEW_REPORTS'],
			generate_report: ['GENERATE_REPORT'],
			table_row: {
				download_file: { permission: ['DOWNLOAD_REPORT_FILE'] },
			},
		},
		recon: {
			permission: ['VIEW_RECON'],
			upload_bank_statement: ['UPLOAD_BANK_STATEMENT'],
			table_row: {
				download_file: { permission: ['DOWNLOAD_RECON_FILE'] },
			},
		},
		fetchClosure: {
			permission: ['VIEW_LOANS_PAISA'],
			upload_bank_statement: ['VIEW_LOANS_PAISA'],
		},
	},
	other: {
		permission: [],
	},
};

export default permissions;
