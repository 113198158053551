import React, { lazy, Suspense } from 'react';
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from 'react-router-dom';
import { NetworkStatusProvider } from '../contexts/network-status-context';
import NotFound from '../pages/not-found/NotFound';
import Login from '../pages/Login';
import PrivateRoutes from './privateRoutes';
import NotificationProvider from '../contexts/notification-context/notificationContext';
import { getUserPermissions } from '../contexts/permission-context';
import { getValueFromCookie } from '../utils/cookies';
import { fetchPermittedRoute } from '../utils/helper';

const Application = lazy(() => import('../pages/application/application'));

export default function OroPaisaRoutes() {
	const userPermissions = getUserPermissions().permissions;
	return (
		<NetworkStatusProvider>
			<NotificationProvider>
				<Router>
					<Suspense>
						<Routes>
							<Route
								path='/'
								element={
									getValueFromCookie('token') ? (
										<Navigate to={fetchPermittedRoute(userPermissions)} />
									) : (
										<Navigate to='/login' />
									)
								}
							/>
							<Route path='/login' element={<Login />} />
							<Route
								path={'/*'}
								element={
									<PrivateRoutes>
										<Application />
									</PrivateRoutes>
								}
							/>
							<Route path='/page-not-found' element={<NotFound />} />
							<Route path='*' element={<Navigate to='/page-not-found' />} />
						</Routes>
					</Suspense>
				</Router>
			</NotificationProvider>
		</NetworkStatusProvider>
	);
}
