import axios from 'axios';
import { makeUseAxios } from 'axios-hooks';
import { REQUEST_TIMEOUT } from './constants';
import { getValueFromCookie } from './cookies';

const headers: { Authorization: string | any } = {
	Authorization: `Bearer ${getValueFromCookie('token')}`,
};

const instance = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
	headers,
	timeout: REQUEST_TIMEOUT,
});

const useAxios = makeUseAxios({
	axios: instance,
});

export default useAxios;
