import React from 'react';
import { Navigate } from 'react-router-dom';
import { getValueFromCookie } from '../utils/cookies';
import { IPrivateRoutesProps } from './interface';

export default function PrivateRoutes({
	children,
}: IPrivateRoutesProps): JSX.Element {
	// fetch token from localstorage
	const TOKEN = getValueFromCookie('token');

	// fetch user data from localstorage

	// check if token and user data exist then render the component
	// else redirect to login
	return TOKEN ? children : <Navigate to={{ pathname: '/' }} />;
}
