import axios, { AxiosError } from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { GoogleLogin } from 'react-google-login';
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { NotificationContext } from '../../contexts/notification-context/notificationContext';
import GoogleIconSvg from '../../components/svg/GoogleIconSvg';
import useVerifyGoogleToken from '../../services/auth';
import errorHandling from '../../services/shared/helpers/helpers';
import Utils from '../../utils';
import { addCookie } from '../../utils/cookies';

// assets
import { fetchPermittedRoute } from '../../utils/helper';

// constants
import {
	ERROR,
	PUSH_NOTIFICATION,
} from '../../constants/common/notificationTypes';

export default function Login() {
	// STATES
	const [token, setToken] = useState('');

	// CONTEXT
	const dispatch = useContext(NotificationContext);

	// HISTORY
	const navigate = useNavigate();

	// call api to verify google token
	const { handleLogin } = useVerifyGoogleToken({ token });
	useEffect(() => {
		if (token) {
			Utils.debounce(() => {
				handleLogin()
					.then((response) => {
						const { data } = response;
						const decodedToken: any = jwt_decode(data?.user?.token);
						if (
							decodedToken &&
							decodedToken.allowed_clients.includes('PAISA')
						) {
							addCookie('token', data?.user?.token);
							addCookie('refreshToken', data?.user?.refreshToken);
							addCookie(
								'user',
								JSON.stringify({
									id: data?.user?.userid,
									name: data?.user?.name,
									email: data?.user?.email,
								})
							);
							const permissionArray = decodedToken.permissions;
							navigate(fetchPermittedRoute(permissionArray));
							window.location.reload();
						} else {
							dispatch({
								type: PUSH_NOTIFICATION,
								payload: {
									id: Math.floor(Math.random() * 100),
									type: ERROR,
									message:
										'Check if you have the relevant permissions and try again.',
								},
							});
						}
					})
					.catch((authError) => {
						// check if the request is not cancled after unmounting the component!
						if (!axios.isCancel(authError)) {
							const error = errorHandling(authError);
							dispatch(error);
						}
					});
			}, 700);
		}
	}, [token]);

	const handleSuccess = (response: any) => {
		setToken(response?.tokenId);
	};

	const handleFailure = (err: AxiosError) => {
		const error = errorHandling(err);
		dispatch(error);
	};
	return (
		<div className="flex items-center w-full h-[100vh] bg-[url('../assets/images/auth-background.svg')] bg-no-repeat bg-cover">
			<div className='shadow-lg px-8 py-8 bg-gray-50 rounded-xl ml-20'>
				<div className='flex flex-col pb-6'>
					<p className='text-primary-grey text-2xl font-bold leading-snug'>
						Login to
					</p>
					<p className='uppercase text-4xl font-black leading-snug'>
						Paisa Dashboard
					</p>
				</div>
				<GoogleLogin
					clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID ?? ''}
					render={({ onClick }) => (
						<button
							onClick={onClick}
							type='submit'
							className='flex px-10 py-4 2xl:px-12 flex-row space-x-3 text-2xl rounded-2xl
              justify-center items-center mt-2 border-4 border-solid border-primary-yellow cursor-pointer shadow-xl'
						>
							<GoogleIconSvg />
							<p className='text-body-large font-bold'>Signin with Google</p>
						</button>
					)}
					onSuccess={handleSuccess}
					onFailure={handleFailure}
					cookiePolicy='single_host_origin'
				/>
			</div>
		</div>
	);
}
