import React from 'react';
import jwt_decode from 'jwt-decode';

// utils
import {
	IPermissionsProviderProps,
	IPermissionsContext,
	IToken,
} from './interfaces';
import { deleteAllCookies, getValueFromCookie } from '../../utils/cookies';

const PermissionContext = React.createContext<IPermissionsContext>({
	permissions: [],
});

export function PermissionsProvider({ children }: IPermissionsProviderProps) {
	const [permissions, updatePermissions] = React.useState<String[]>([]);
	const value = React.useMemo(() => ({ permissions }), [permissions]);

	const updatePermissionsFromToken = () => {
		// get token from cookie
		const token = getValueFromCookie('token');
		if (token) {
			try {
				const decodedToken: IToken = jwt_decode(token);
				if (decodedToken) {
					const permissionArray: String[] = decodedToken.permissions;
					// get and update permissions here from user token after decoding
					updatePermissions(permissionArray);
				}
			} catch (e) {
				deleteAllCookies();
			}
		}
	};

	React.useEffect(() => {
		updatePermissionsFromToken();
	}, [getValueFromCookie('token')]);

	return (
		<PermissionContext.Provider value={value}>
			{children}
		</PermissionContext.Provider>
	);
}

export function getUserPermissions() {
	const context = React.useContext(PermissionContext);
	if (context === undefined) {
		throw new Error(
			'getUserPermissions must be used within PermissionsProvider'
		);
	}
	return context;
}
